var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "4",
        height: "16",
        viewBox: "0 0 4 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", { attrs: { cx: "2", cy: "2", r: "2", fill: "#C4C4C4" } }),
      _c("circle", { attrs: { cx: "2", cy: "8", r: "2", fill: "#C4C4C4" } }),
      _c("circle", { attrs: { cx: "2", cy: "14", r: "2", fill: "#C4C4C4" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }